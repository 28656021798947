/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import {Footer} from "./footer";

export const SimpleLayout = ({ children }) => {
    return (
        <>
          <div>
            <main>{children}</main>
          </div>
        </>
      )
};

export const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <Footer/>
      </div>
    </>
  )
};

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
