import React from "react"

import "../styles/footer.scss"

export const Footer = () => {
    return (
        <footer className="footer">
            © {new Date().getFullYear()} Artur Malarz. All rights reserved
        </footer>
    )
}